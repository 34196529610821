@charset "UTF-8";
@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');
@import '~owl.carousel/dist/assets/owl.carousel.css';
@import '~owl.carousel/dist/assets/owl.theme.default.css';
@import '~select2/dist/css/select2.min.css';
@import '~flatpickr/dist/flatpickr.min.css';
@import "~jquery-ui/themes/base/all.css";

$white: #fff;
$black: #000;
$darkBlue: #0e3c66;
$lightBlue: #1e6c9c;
$petrol: #28b0b0;
$galazioDark: #1584bc;
$galazioLight: #2eb8d5;
$orange: #ef5630;
$fontGrey: #444444;
$blueOverlay: #065b82;
$darkGrey: #2b3132;
//gradient from darkblue to lightblue

@mixin bgProperty ( $height, $bgSize, $bgPosition, $radius) {
    height: $height;
    background-size: $bgSize;
    background-position: $bgPosition;
    border-radius: $radius;
    background-repeat: no-repeat;
}

@mixin centerElement {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@mixin buttons($border, $radious, $bgColor, $textColor) {
    border: $border;
    border-radius: $radious;
    background-color: $bgColor;
    color: $textColor;
}

@mixin newsletterPrototype($bgColor, $color, $position, $padding) {
    background-color: $bgColor;
    color: $color;
    text-align: $position;
    padding: $padding;
}
// checkbox - radio
@mixin inputRadio ($width, $height, $link_color, $border, $radius) {
    width: $width;
    height: $height;
    color: $link_color;
    border: $border;
    border-radius: $radius;
    -webkit-appearance: none;
    position: relative;
}

@mixin inputRadioBefore ($content, $top, $left, $color, $fSize) {
    content: $content;
    top: $top;
    left: $left;
    color: $color;
    font-size: $fSize;
    position: absolute;
}
// .checkbox - radio
@mixin copyright ($bgColor, $size, $pad, $border) {
    background-color: $bgColor;
    font-size: $size;
    padding: $pad;
    border-top: $border;
    text-align: center;
}

@mixin footer($bgColor, $color, $font-size, $padding) {
    background-color: $bgColor;
    color: $color;
    font-size: $font-size;
    padding: $padding;
}

@mixin generalTitles($w,$s,$b,$p,$c) {
    font-weight: $w;
    font-size: $s;
    border-bottom: $b;
    padding-bottom: $p;
    color: $c;
}

@mixin paddingGeneral($t,$r,$b,$l) {
    padding-top: $t;
    padding-right: $r;
    padding-bottom: $b;
    padding-left: $l;
}

@import "cms";

// ::selection{background-color: transparent;color:$grey;}
// ::-webkit-scrollbar{width: 10px;background: $green;}
// ::-webkit-scrollbar-thumb {background: $black;}
*:hover, *:focus, input:focus{
    outline: none;
    border-color: none !important;

}
input:focus, button:focus, textarea:focus, select:focus {
    box-shadow: 0 0 0 0rem rgba(0,0,0,0) !important;
}

ul {
    padding-left: 0;
}
// svg{position: absolute; left:0; top:0;}
body {
    position: relative;
    background-color: $white;
    color: $darkGrey;
    font-weight: bold;
    font-family: 'Open Sans', sans-serif;
    // font-family:  'Poppins', sans-serif;
    a {
        text-decoration: none;

        &:hover, &:focus, &:active {
            text-decoration: none;
            outline: none;
        }
    }

    ul {
        margin-bottom: 0;
    }
}
//PRE BANNES
.introParagraphs {
    background-color: #f7f7f7;
    color: $fontGrey;
    padding-top: 40px;
    padding-bottom: 40px;

    p {
        font-weight: normal;
        font-size: 15px;
        width: 80%;
        margin: auto;
        padding-top: 10px;
    }

    img {
        width: 70px;
    }
}

//SERVICES
.services {
    padding-top: 50px;
    padding-bottom: 50px;

    h2 {
        padding-bottom: 80px;
        font-weight: 700;
        color: $darkGrey;
    }

    .servicesWrapper {
        /*border: solid 1px #ccc;*/
        text-align: center;
        padding: 10px 20px;
        margin-bottom: 60px;

        img {
            border-radius: 3px;
            padding: 8px 6px;
            width: 60px;
            height: auto;
        }

        h4 {
            color: $darkGrey;
            font-size: 16px;
            padding-top: 10px;
            font-weight:700;
        }

        p {
            font-weight: normal;
            font-size: 15px;
            color: #757474;
        }

        &:hover {
            box-shadow: -5px 7px 38px -8px rgba(0, 0, 0, 0.41);
        }
    }
}

.init {
    padding-left: 0;
    padding-right: 0;
}
//HEADERS
.header {
    .gridArea {
        display: grid;
        grid-template-columns: 20px 1fr;

        i {
            vertical-align: -webkit-baseline-middle;
            padding-top: 5px;
        }

        .gridAreaContent {
            display: grid;
            grid-template-rows: 15px 20px;
            padding-left: 5px;
        }

        @media screen and (max-width:992px) {
            display: none;
        }
    }
    /*height: 78px;*/
    background-color: $darkBlue;
    color: $white;
    font-weight: 500;
    padding-top: 15px;
    padding-bottom: 15px;

    a {
        color: $white;

        &:hover {
            opacity: 0.6;
        }
    }

    .logo img {
        width: 200px;
        height: auto;
    }

    .mainMenu {
        ul {
            list-style: none;
            text-align: right;
            padding-top: 5px;

            li {
                display: inline-block;
                padding: 0 15px;
                font-size: 15px;
                vertical-align: -webkit-baseline-middle;
                position: relative;

                &:last-child::after {
                    content: "";
                    width: 1.5px;
                    height: 31px;
                    top: -4px;
                    right: 2px;
                    background-color: $white;
                    position: absolute;
                    margin-left: 6px;
                    /*border-right: solid 2px $white;
                    padding-left: 10px;*/
                    @media screen and (max-width:992px) {
                        opacity: 0;
                    }
                }

                &:hover ul {
                    display: block;
                }

                ul {
                    display: none;
                    position: absolute;
                    left: 0px;
                    top: 20px;
                    background: linear-gradient(170deg, $darkBlue 43%, $galazioDark 100%);
                    width: 130px;
                    border-radius: 0 0 10px 10px;
                    z-index: 9;

                    li {
                        display: block;
                        text-align: left;
                        padding-top: 10px;
                        border-radius: 10px;
                        padding-bottom: 10px;

                        &:hover {
                            background-color: #175e9e;
                            transition: all 0.5s;

                            a {
                                opacity: 1;
                            }
                        }

                        &:last-child::after {
                            content: "";
                            opacity: 0;
                        }
                    }
                }
            }
        }
    }
}

.slide-icon-active{
    border-bottom: solid #3c69af;
    img{
        filter: brightness(1) sepia(0.5) hue-rotate(170deg) saturate(5);
    }
}
.slide-icon{
    img{
        filter:brightness(2) sepia(0) hue-rotate(170deg) saturate(0);
    }
}
.toggle {
    width: 28px;
    height: 30px;
    margin: 0px auto;
}

.toggle div {
    width: 100%;
    height: 5px;
    background: white;
    margin: 4px auto;
    transition: all 0.3s;
    backface-visibility: hidden;
    border-radius: 3px;
}

.toggle.on .one {
    transform: rotate(45deg) translate(5px, 5px);
}

.toggle.on .two {
    opacity: 0;
}

.toggle.on .three {
    transform: rotate(-45deg) translate(7px, -8px);
}

.menu {
    color: white;
    height: 180px;
    padding: 10px;
    border-radius: 3px;
    line-height: 40px;
    text-align: center;
    margin: auto;
    display: none;
}
.no-color-li{color:#444444}

.no-color-li:hover{color:#444444}

@media screen and (min-width:767px) {
    .toggle {
        display: none;
    }

    .mainMenu {
        display: inline;
    }
    .menu {
        height: 78px;
        padding: 0px;
        line-height: inherit;
    }
}

@media screen and (max-width:992px) {
    .header {
        .mainMenu {
            ul {
                //text-align: left;
                padding-top: 0px;

                li {
                    padding-left: 15px;
                }
            }
        }
    }
}

@media screen and (max-width:767px) {
    .header {
        text-align: center;
        padding-top: 0px;
        padding-bottom: 5px;

        i {
            display: none;
        }

        .mainMenu {
            display: none;

            ul {
                text-align: center;

                li {
                    display: block;

                    &:last-child::after {
                        border-right: none;
                    }
                }
            }
        }
    }
}
//COUNTERS
.counters {
    @include bgProperty( 300px, cover, 50% 25%, 0 );
    background-attachment: fixed;
    padding-top: 80px;
    padding-bottom: 120px;
    color: $white;

    h3 {
        padding: 10px 0 0 0px;
        font-size: 35px;
        font-weight: bold;
        z-index: 9;
        color: $white;

        @media screen and (max-width:767px) {
            padding: 30px 0 30px 40px;
        }
    }

    .countersFirst {
        position: relative;
        background-color: $white;
        color: $white;



        @media screen and (max-width:767px) {
            margin-bottom: 40px;
        }
    }

    .counterWrapper {
        color: white;
        font-size: 12px;
        width: 120px;
        text-align: center;

        h3 {
            margin: 0;
        }

        /*img {
            border: solid 2px $darkBlue;
            padding: 5px 0px;
            margin-bottom: 17px;
        }*/
    }
}
//TESTIMONIALS
.testimHeader {
    h2 {
        font-weight: bold;
        margin-bottom: 0;
        padding-top: 50px;
        padding-bottom: 50px;
    }
}
.testimonialsWrapper {
    background-color: #f7f7f7;
    padding-bottom:80px;
}
.testimonials {
    background: linear-gradient(90deg, $galazioDark, $galazioLight);
    color: $white;
    padding-top: 60px;
    padding-bottom: 30px;
    border-radius: 30px;

    .testimonialsText {
        font-size: 15px;
        font-weight: 300;
        padding: 0px 40px 0 44px;
        max-height: 140px;
        height: 140px;
        overflow-x: auto;
    }

    h2 {
        padding-bottom: 30px;
    }

    h3 {
        font-size: 16px;
        padding: 15px 40px;
    }

    .owl-theme .owl-dots .owl-dot span {
        border-radius: 0 !important;
    }

    .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
        background: #ffffff4f;
    }

    .icon {
        position: absolute;
        top: 40%;
        transform: translatex(-50%);
        font-size: 22px;
    }

    .iconleft {
        left: -50px;
        color: #777;
    }

    .iconright {
        right: -70px;
        color: #777;
    }

    .owl-dots {
        display: none !important;
    }

    .doublequote {
        padding-left:45px;
        img{
        width: 80px;
        height: auto;}
    }
}

//COMPANY
.company {
    padding: 60px;

    .companyTexts {
        padding-left: 20px;

        h3 {
            color: $darkBlue;
            margin: 20px 0;
            font-weight:bold;
        }

        ul {
            color: $fontGrey;
            padding-left: 10px;
            list-style: none;
            margin-bottom: 20px;
        }

        .linkTypeButton {
            background-color: $orange;
            width: fit-content;
            font-size: 13px;
            border-radius: 30px;
            padding: 10px 30px;

            a {
                color: $white;
            }
        }
    }
}

//FOOTER
.footer {
    background: linear-gradient(90deg, $darkBlue, $lightBlue);
    color: $white;

    .textFooter {
        opacity: 0.6;
        font-size: 14px;
        line-height: 25px;
        padding-bottom: 20px;
    }

    .inputCust {
        position: relative;

        input {
            border-radius: 0;
        }

        .relInput {
            position: absolute;
            top: 0;
            right: 0;
            background-color: $orange;
            border: none;
            padding: 7px 17px;
            color: $white;
        }
    }

    .basicRow {
        padding-top: 50px;
        padding-bottom: 50px;
        border-top: solid 1px #ffffff47;
        border-bottom: solid 1px #ffffff47;
    }

    .bsList {
        padding-left: 0;
        list-style: none;

        li:not(:first-child) {
            opacity: 0.6;
            font-size: 14px;
            padding-bottom: 5px;
        }

        li:first-child {
            padding-bottom: 15px;
        }
    }

    .splist {
        padding-top: 10px;

        li {
            display: inline-block;
            background-color: $darkBlue;
            padding-top: 10px;
            width: 40px;
            height: 40px;
            padding-right: 11px;
        }
    }

    .charges {
        font-size: 14px;

        .orange {
            color: $orange;
            font-size: 20px;
        }

        .infoFooter {
            padding-top: 20px;
            font-size: 13px;
        }
    }

    .helpFooter {
        padding-bottom: 15px;
        font-size: 16px;
    }

    p {
        opacity: 0.6;
        padding: 20px 0;
        margin-bottom: 0;
    }

    a {
        color: $white;

        &:hover {
            opacity: 0.5;
        }
    }

    .logo {
        width: 170px;
        height: auto;
        margin-top: 10px;

        @media screen and (max-width:767px) {
            img {
                padding-top: 15px;
            }
        }
    }
}

.headerBanner {
    background-position: center;
    background-size: cover;
    height: 250px;
    background-repeat: no-repeat;

    h3 {
        color: $white;
        font-weight: 300;
    }

    h2 {
        color: $white;
        max-width: 60%;
        margin:auto;
    }

    .pdTop {
        padding-top: 8vh;
    }

    @media screen and (max-width:992px) {
        h2 {
            max-width: 100%;
        }
    }

    @media screen and (max-width:580px) {
        h2 {
            font-size: 20px;
        }
    }
}

.contentEasf {
    color: $darkGrey;
    font-weight: normal;
    text-align: center;
    padding-top: 70px;
    padding-bottom: 50px;
    color: $fontGrey;
}

.borderCompany {
    padding-top: 30px;
    border-bottom: solid 2px #ccc;
}

.team {
    h3 {
        padding-bottom: 50px;
    }

    .gradientStyle {
        // background: linear-gradient($white, #ccc);
        margin-bottom: 50px;
    }

    .personsInfo {
        background-color: $white;
        padding-top: 15px;

        h2 {
            font-size: 14px;
        }

        h3 {
            font-size: 13px;
            margin-bottom: 0;
        }
    }

    .init {
        padding-left: 0;
        padding-right: 0;
    }
}

.companybanner {
    background-position: center;
    background-size: cover;
    height: 500px;
    position: relative;
    z-index: 0;

    .gridArea {
        color: $white;
        display: grid;
        margin-top: 35px;
        grid-template-columns: 20px 1fr;
        width: 300px;
        margin: auto;

        small {
            margin-top: 25px;
        }

        a {
            color: $white;
            font-size: 35px;
        }

        i {
            vertical-align: -webkit-baseline-middle;
            color: $petrol;
            font-size: 35px;
            padding-top: 15px;
        }

        .gridAreaContent {
            display: grid;
            grid-template-rows: 20px 20px;
            padding-left: 5px;
        }
    }

    h2 {
        color: $white;
        z-index: 2;
        padding-top: 50px;
        font-size: 40px;
    }

    .overlayBanner {
        background-color: $darkBlue;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.9;
        z-index: 0;
    }
}
.rebaseContactFrom {
    height: 75vh !important;

    @media screen and (max-width:767px) {
        height: 100% !important;
    }
}
.formArea {
    height: 50vh;
    background-color: #f1efef;
    /*margin-bottom: 300px;*/
    .white {
        background-color: $white;
        color: $fontGrey;
        padding-left: 30px;
        padding-right: 30px;
    }

    .grey {
        background-color: $fontGrey;
        color: $white;

        h3 {
          color: #fff;
        }
    }

    h3 {
        margin: 15px 0 30px 0;
        font-size: 20px;
    }

    ul {
        list-style: none;
        margin-bottom: 30px;
        font-weight: 300;

        li:first-child {
            font-weight: 700;
        }
    }

    .form-control {
        border: none;
        border-radius: 0;
        border-bottom: solid 2px #ccc;
        padding: 0;
        margin-bottom: 30px;
    }

    label {
        font-weight: 400;
        color: #6c757d;
    }

    .formButton {
        text-align: right;

        button {
            background-color: $orange;
            border: none;
            border-radius: 10px;
            color: $white;
            font-size: 15px;
            padding: 10px 25px;
            margin-bottom: 30px;
        }
    }

    .pinIt {
        box-shadow: 2px 2px 22px #8e8d8d;
        margin-top: -200px;
    }

    .unpinItContact {
        margin-top: 0;
    }

    @media screen and (max-width:767px) {
        height: 100vh;
    }
}

.formAreaContact {
    height: 65vh;
    background-color: #f1efef;
    padding-top: 100px;
    min-height: 600px;
    @media screen and (max-width:767px) {
        height: 108vh;
        padding-top: 50px;
        min-height: 900px;
    }

    @media screen and (max-width:575px) {
        padding-top: 0px;
    }
}

@mixin inputRadio ($width, $height, $link_color, $border, $radius) {
    width: $width;
    height: $height;
    color: $link_color;
    border: $border;
    border-radius: $radius;
    -webkit-appearance: none;
    position: relative;
}

@mixin inputRadioBefore ($content, $top, $left, $color, $fSize) {
    content: $content;
    top: $top;
    left: $left;
    color: $color;
    font-size: $fSize;
    position: absolute;
}

.bgForm {
    background-color: #f7f7f7;
    /*min-height: 900px;*/
    padding-bottom: 20px;
}

.basicForminsurance {
    color: $fontGrey;

    h4 {
        margin-bottom: 20px;
        font-size: 95%;
    }

    label {
        font-weight: 500;
        min-width: 50%;
        width:100%;
    }

    label.invalid {
        display: none !important;
    }

    select {
        width: 96%;
        border: solid 1px #ccc;
        border-radius: 3px;
        height: 40px;
        color: #6c757d;
        padding-left: 5px;
    }

    select:disabled {
        background-color: #e9ecef;
        opacity: 1;
    }

    .borderB {
        border-bottom: solid 2px $darkBlue;
    }

    .form-control {
        width: 96%;
    }
}

.inputRadio input[type="radio"], .inputCheckbox input[type="checkbox"] {
    @include inputRadio(25px, 25px, #ccc, solid 1px #ccc, 0px);
    border-radius: 50%;

    &:hover {
        /*border: solid 2px $darkBlue;*/
        cursor: pointer;
    }

    &:checked {
        border: solid 2px $darkBlue;
        background-color: $darkBlue;
    }

    &:checked::before {
        @include inputRadioBefore('\2713', -6px, 1px, $white, 20px);
    }
}

.inputStyleBtn {
    border: solid 1px #ccc;
    padding: 8px 8px 0 8px;
    border-radius: 3px;
    background-color: $white;
    width: 91%;

    span {
        vertical-align: super;
        padding-left: 30%;
        font-weight: 500;
        color: #6c757d;

        @media screen and (max-width:992px) {
            padding-left: 20%;
        }
    }
}

.deviderForms {
    border-bottom: solid 2px $darkBlue;
    margin: 30px 0;
}
.payway {
    h4 {
        margin-bottom: 0;
        font-size: 1.5rem;
        color: $darkGrey;
    }

    @media screen and (max-wisdth:992px) {
        .donate-now label {
            padding: 15px 10px 5px 10px;
        }
    }
}
.nav-tabs {
    border-bottom: none;
}

.nav-tabs .nav-link.active {
    border-color: transparent;
    color: $white;
    background-color: $darkBlue;
    border-radius: 3px;
}

.nav-tabs .nav-link {
    border: 1px solid #ced4da;
    border-radius: 3px;
    color: $fontGrey;
}

.nav-item {
    padding-right: 15px;
    margin-bottom: 20px !important;

    @media screen and (max-width:767px) {
        font-size: 55%;
    }
}

.extraStyle {
    margin-top: 20px;
}

.termsOfUse {
    padding-top: 60px;
    padding-bottom: 60px;

    a {
        color: $orange;
        text-decoration: underline;
    }

    .termsText {
        margin-bottom: 60px;
        border-top: dotted 1px #ccc;
        padding-top: 20px;

        span {
            padding-left: 5px;
            font-weight: 500;
            color: $fontGrey;
        }
    }

    .termsText.field-error span {
        color: red;
        font-weight: 800;
    }
}

.edit-link {
    width: 95%;
}

.termsText input[type="checkbox"] {
    @include inputRadio(15px, 15px, #ccc, solid 2px #262626, 0px);

    &:hover {
        border: solid 2px $darkBlue;
        cursor: pointer;
    }

    &:checked {
        border: solid 2px $orange;
    }

    &:checked::before {
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        @include inputRadioBefore('\f00c', -3px, 1px, #262626, 11px);
        content: "\f00c";
    }
}

.analyse {
    span {
        vertical-align: super;
    }
}

.form-group {
    position: relative;
}

.tooltipInfo {
    border-radius: 50%;
    background-color: transparent;
    color: $white;
    font-size: 15px;
    border: none;
    position: absolute;
    right: 0;
    top: 55%;
    width: 17px;
    padding: 0;

    i {
        color: $fontGrey;
    }

    @media screen and (max-width:992px) {
        right: -8px;
    }
}
//progress steps
.progressSteps {
    position: relative;
    z-index: 0;

    ul {
        padding-left: 0;
        margin-top: 60px;

        li {
            display: inline-block;
            padding: 6px 13px;
            border-radius: 50%;
            background-color: #ccc;
            color: $white;
            margin: 0 30px;

            @media screen and (max-width:480px) {
                margin: 0 10px;
            }
        }

        .activeStep {
            background-color: $darkBlue;
        }
    }

    .progerssStepsBar {
        position: absolute;
        width: 20%;
        height: 2px;
        background: linear-gradient(to left, #ccc 50%, #ccc 75%, $darkBlue 75%);
        top: 81%;
        margin: auto;
        text-align: center;
        left: 50%;
        transform: translate(-50%);
        z-index: -1;

        @media screen and (max-width:992px) {
            width: 28%;
        }

        @media screen and (max-width:767px) {
            width: 33%;
        }

        @media screen and (max-width:580px) {
            width: 36%;
        }
    }
}
.sliderSec2sm {
    padding: 45px 0;

    &:hover {
        background-color: #075c85;
        transition: all 0.9s;
        cursor: pointer;
    }
}
.sliderSec2 {
    background: linear-gradient(90deg, $darkBlue, $lightBlue);

    p {
        text-align: center;
        color: $white;
        margin-bottom: 0;
        margin-top:25px;
        @media screen and (max-width:676px) {
            font-size: 75%;
        }
    }

    img {
        width: 60px !important;
        height: auto;
        margin: auto;
        margin-bottom: 10px;
    }

    .sliderSec2sm {
        padding: 45px 0;

        &:hover {
            background-color: #075c85;
            transition: all 0.9s;
            cursor: pointer;
        }
    }
}
#sync2 {
    .owl-stage-outer {
        .owl-stage {
            .owl-item {
                @media screen and (max-width:676px) {
                    float: none;
                    display: block;
                    margin: auto;
                }
            }
        }
    }
}

.activeSlider {
    background-color: #075c85 !important;
}
.helperSlider {
    min-height: 80vh;
}
.sliderPhotos {
    position: relative;
    /*min-height: 100vh;*/
    .sliderPhotoinner {
        background-position: top;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-color: #eee;
        height: 635px;
        /*min-height: 550px;*/
        padding: 80px 0px;

        @media screen and (max-width:767px) {
            height: 770px;
            padding: 0px 0px;
            background-size: cover;
        }
    }
}

#sync1 {
    text-align: right;
    color: $white;

    h2 {
        font-size: 70px;
        padding-top: 22px;
        text-align: left;
        font-weight: bold;
        line-height: 65px;

        @media screen and (max-width:992px) {
            font-size: 50px;
        }

        @media screen and (max-width:767px) {
            font-size: 29px;
            text-align: left;
            line-height: 33px;
        }
    }

    span {
        font-size: 20px;
        font-weight: 300;
    }

    @media screen and (max-width:767px) {
        text-align: center;
    }
}

.carInsurance {
    margin-top: 45px;
    background-color: white;
    padding: 15px 30px;
    width: 90%;
    min-height: 350px;
    border-radius: 30px;
    box-shadow: -9px 5px 47px #383737;

    .carInsuranceInput {
        border-radius: 10px;
        background-color: #ebebeb;
        border-color: #ebebeb;
        border: solid 2px #ebebeb;
        height: 45px;
    }

    input:focus, select:focus, input:hover, select:hover {
        border: solid 2px $orange;
        background-color: initial;
    }

    input, select{margin-bottom:20px;}

    h3 {
        color: $darkGrey;
        margin-bottom: 23px;
        font-size: 25px;
    }

    button {
        color: $white;
        background-color: $orange;
        border: none;
        border-radius: 10px;
        padding: 10px 25px;
        font-weight: 500;
    }

    .select2-container {
        margin-bottom: 40px;
        border-radius: 1px;
        background-color: #e8e7e3;
        border: solid 1px $white;
        width: 100% !important;

        /*&:focus, &:hover {
            border: solid 1px $orange;
        }*/
    }

    select {
        color: #495057;
        width: 100%;
        padding: 0 10px;
    }

    span {
        color: #747879;
        font-weight: 400;
    }

    @media screen and (max-width:767px) {
        width: 100%;
        margin-top: 5px;
    }

    .foreigner {
        border: solid 1px #e8e7e3;
        background-color: #e8e7e3;
        height: 38px;
        padding-top: 3px;
        margin-bottom: 17px;
        input{margin-bottom:0px;}
    }

    .foreigner input[type="radio"] {
        @include inputRadio(15px, 15px, #ccc, solid 2px #747879, 0px);

        &:hover {
            border: solid 2px #747879;
            cursor: pointer;
        }

        &:checked {
            border: solid 2px #747879;
        }

        &:checked::before {
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            @include inputRadioBefore('\f00c', -3px, 1px, #747879, 11px);
            content: "\f00c";
        }
    }
}

.houseSum {
    h5 {
        color: $fontGrey;
    }

    color: $orange;
    padding-bottom: 30px;
}

.foreignerResponse, #validateTruckResponse, #validateMotorRepsonse, #validateRepsonse {
    color: $orange;
    margin-bottom: 15px;
}

#foreignerResponse {
    padding-left: 15px;
}

.donate-now {
    list-style-type: none;
    /*margin: 25px 0 0 0;*/
    padding: 0;
}

.donate-now li {
    float: left;
    margin: 0 5px 0 0;
    display:inline-block;
}

.donate-now label {
    padding: 15px 100px 5px 100px;
    border: 1px solid #CCC;
    cursor: pointer;
}

.donate-now label:hover {
    background: #DDD;
}
.donate-now span {
    vertical-align: super;
}
.current {
    background-color: #075c85;
}
@media screen and (max-width:676px) {
   .current{ width:100% !important;}
}

#sync2 .item h1 {
    font-size: 18px;
}

#sync2 .synced .item {
    background: #0c83e7;
}
#sync3{
    .current{
        background:white !important;
    }
}
.form-icons{
    cursor: pointer;
    .owl-stage-outer{
        .owl-stage{
            margin-left:6px;
            padding-bottom:20px;
            width: 350px !important;
            .owl-item{
                width: 80px !important;
                margin-left:20px;
            }
        }
    }
}
small{padding-top:8px;}
.carInsurance {
    .row{
        .text-left{
            h3{
                color:#0e3c66;
            }
        }
    }
    .select2-container .select2-selection--single .select2-selection__rendered {
        text-align: left;
        font-size: 17px !important;
        background-color: #ebebeb;
        border: solid 2px #ebebeb !important;
        border-color: #ebebeb !important;
        border-radius: 10px;

        &:hover {
            background-color: $white !important;
            border: solid 2px #000 !important;
            border-color: $orange !important;
        }
    }
}
.basicForminsurance {
    .select2-container .select2-selection--single .select2-selection__rendered {
        text-align: left;
        font-size: 17px !important;
        background-color: #fff;
        border: solid 1px #ced4da !important;
        border-color: #ced4da !important;
        border-radius: 2px;

        &:hover {
            background-color: $white;
        }
    }

    .select2-container .select2-selection--single .select2-selection__rendered {
        background-color: #fff;
        border: solid 1px #ced4da !important;
        border-color: #ced4da !important;
        border-radius: 4px;
    }

    .select2-container--disabled .select2-selection--single .select2-selection__rendered,
    .select2-container--disabled .select2-selection--single .select2-selection__rendered:hover
    { background-color: #e9ecef; }

    .field-error input,
    .field-error select,
    .field-error input[type=radio],
    .field-error input[type=checkbox]
    {
        border: 1px solid red;
    }
}

/*.select2-container .select2-selection--single .select2-selection__rendered {
    text-align: left;
    font-size: 17px !important;
    background-color: #ebebeb !important;
    border: solid 2px #ebebeb !important;
    border-color: #ebebeb !important;
    border-radius: 10px;

    &:hover {
        background-color: $white !important;
        border: solid 2px #000 !important;
        border-color: pink !important;
    }
}*/
.select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #495057;
}
.select2-container--default .select2-selection--single {
    border: none !important;
    border-radius: 0;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
    padding: 0.375rem 0.75rem;
}
.select2-container--default .select2-selection--single .select2-selection__arrow{top:8px;}
.select2-container--open .select2-dropdown{top:15px;}
.select2-container--default .select2-selection--single .select2-selection__arrow b { left: auto; right: 20px;}

.splitInputs {
    float: left;
    width: 49% !important;
    margin-right: 1%;
}
.showSched {
    -webkit-appearance: none;
    border-radius: 50%;
    border: none;
    background-color: transparent;
    i {
        vertical-align: unset;
        font-size: 11px;
    }
}
#collapseExamples {
    padding: 20px 0;
    background-color: #fff;

    p {
        margin-bottom: 0;
        padding: 6px 10px 6px 10px;
        font-size: 14px;
    }

    .borderCollapsed {
        border-bottom: solid 1px #ccc;
        padding-left: 0;
        padding-right: 0;

        .form-group {
            margin-bottom: 0;
        }
    }

    .row {
        padding-left: 15px;
        padding-right: 15px;
    }
}
.checkTimesStyle {
    i {
        padding-left: 20px;
        font-size: 14px;
        vertical-align: sub;
    }
}
.svgImage{
    width:80px;
    height:auto;
}
.svgImage1 {
    width: 50px;
    height: auto;
}
//RESULT PAGE

.secondeStep {
    width: 20%;
    background: linear-gradient(to left, #ccc 29%, #ccc 13%, $darkBlue 25%) !important;

    @media screen and (max-width:992px) {
        width: 28%;
    }

    @media screen and (max-width:767px) {
        width: 33%;
    }

    @media screen and (max-width:580px) {
        width: 36%;
    }
}
.invalidClass{border:solid 1px red !important;}
.inscuranceTypeWrapper {
    border-right: dotted 1px #ccc;
    margin-bottom: 50px;

    label {
        width: 100%;
    }

    .nav-item {
        margin-bottom: 5px !important;
        width: 100%;
        text-align: center;
    }

    .nav-tabs .nav-link.active {
        background-color: $galazioLight;
    }

    .nav-tabs .nav-link.disabled {
        border: 1px solid #ced4da;
    }

    select {
        width: 95%;
        border: solid 1px #ccc;
        border-radius: 3px;
        height: 40px;
        color: #6c757d;
    }

    .checkboxInsu {
        margin-bottom: 10px;

        span {
            padding-left: 5px;
            font-weight: 500;
            color: $fontGrey;
        }
    }

    .termsOfUse {
        padding: 0px 0 !important;
    }

    .checkboxInsu input[type="checkbox"] {
        @include inputRadio(15px, 15px, #ccc, solid 2px #ccc, 0px);

        &:hover {
            border: solid 2px $darkBlue;
            cursor: pointer;
        }

        &:checked {
            border: solid 2px $lightBlue;
        }

        &:checked::before {
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            @include inputRadioBefore('\f00c', -3px, 1px, $lightBlue, 11px);
            content: "\f00c";
        }
    }
}

.insuranceSalesWrapper {
    .basicStyle {
        background-color: $white;
        border-bottom: solid 3px $galazioLight;
        margin-bottom: 10px;

        .borderR {
            border-right: dotted 1px #ccc;
        }

        .borderL {
            border-left: dotted 1px #ccc;
        }
    }

    .finalCost {
        color: $darkBlue;
        font-size: 45px;
        padding: 35px 0 15px 0;
    }

    .buyBtn {
        color: #fff;
        background-color: #ef5630;
        border: none;
        border-radius: 15px;
        padding: 5px 25px;
        font-weight: 500;
        margin-bottom: 20px;
    }

    .analysisIns {
        color: $darkBlue;
        padding: 15px 0;
        border-top: dashed 1px #ccc;
        padding-left: 15px;

        @media screen and (max-width:991px) {
            display: none;
        }
    }

    .companiesLogo {
        padding-left: 15px;
        padding-right: 15px;
    }

    .bgCompaniesLogo {
        @include bgProperty(150px, contain, center,0);
    }

    .listStyle {
        ul {
            padding: 10px 0 10px 15px;

            li {
                color: $white;
                font-weight: normal;

                b {
                    font-weight: 900;
                }
            }
        }
    }

    #cover {
        background: linear-gradient(90deg, $galazioDark, $galazioLight);
        width: 100%;
    }

    .showSched {
        -webkit-appearance: none;
        border-radius: 50%;
        border: solid 1px transparent;
        background-color: $white;
        height: 25px;
        width: 25px;
        cursor: -webkit-grab;
        cursor: grab;

        i {
            vertical-align: unset;
            font-size: 11px;
        }
    }

    .alert {
        width: 100%;

        i {
            font-size: 25px;
            border: solid 5px #856404;
            border-radius: 50%;
            width: 50px;
            height: 50px;

            &::before {
                padding-left: 15px;
                vertical-align: sub;
            }
        }
    }

    .exlcGrid {
        display: grid;
        grid-template-columns: 60px auto;
    }
}

.ifUnder992 {
    @media screen and (min-width:992px) {
        display: none;
    }

    .analysisInsuarance {
        color: $darkBlue;
        padding: 15px 0;
        border-top: dashed 1px #ccc;
        padding-left: 15px;
    }
}

.backgroungChange {
    background: linear-gradient(90deg, $galazioDark, #1a8ec1);
    color: $white !important;
    transition: background-color 400ms cubic-bezier(0.2,0.5,0.1,0);
}

.backgroungChange992 {
    background: linear-gradient(90deg, $galazioDark, $galazioLight);
    color: $white !important;
    transition: background-color 400ms cubic-bezier(0.2,0.5,0.1,0);
}

.insurancesWrapperGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 2fr;
    text-align: center;
    color: $darkBlue;
    padding-top: 30px;

    a {
        color: $darkBlue;
    }

    div {
        padding: 20px 0;
    }

    .checkboxNo3 {
        @include inputRadio(25px, 25px, #ccc, solid 2px #ccc, 5px);

        &:hover {
            border: solid 2px $galazioLight;
            cursor: pointer;
        }

        &:checked {
            border: solid 2px $galazioLight;
            background-color: $galazioLight;
        }

        &:checked::before {
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            @include inputRadioBefore('\f00c', -3px, 1px, $white, 20px);
            //background-color: $galazioDark;
            content: "\f00c";
        }
    }
}
.nextBtn {
    color: #fff;
    background-color: #ef5630;
    padding: 10px 27px;
    border-radius: 10px;
    border:solid 2px $orange;
}
.overlayLoader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.3);
    width: 100%;
    height: 100%;
    z-index:999;
}

.loaderWrapper {
    @include bgProperty (400px, contain, right, 0);
    display: grid;
    grid-template-columns: 63% 37%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color: $white;
    width: 50%;
    height: 65%;
}

.overlayTexts {
    background: linear-gradient(-80deg, #fff 14%, #fff 7%, #45a91c 6%);
    padding: 30px 0 30px 30px;
    .loaderMainTitle {
        color: $darkBlue;
        h3{
            width:80%;
            font-weight:bold;
        }
    }

    .loaderMainText {
        color: $white;
        p{
            margin-top:20px;
            width:70%;
        }
    }
}
.workingLoader {
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 0;
    height: 10px;
    -moz-transition: all 4s ease;
    -moz-transition-delay: 1s;
    -webkit-transition: all 4s ease;
    -webkit-transition-delay: 1s;
    transition: all 4s ease;
    transition-delay: 1s;
}
.regNumberOximatos{
    text-transform:uppercase;
}
//.registration number to request page upprcase
.preloaderSlider {
    z-index: 999999999;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    height: 100vh;
    background-color: #919090a6;
    width: 100%;
}
//loader
.lds-ellipsis {
    display: inline-block;
    width: 64px;
    height: 64px;
    position: absolute;
    left: 45%;
    top: 40%;
    background: url('img/frontend/Loader.gif');
    background-repeat: no-repeat;
    background-size: 64px 64px;
}

//ajax loader
.myModal {
    width: 100%;
    height: 100vh;
    background-color: $black;
    opacity: 0.7;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
    pointer-events: none;

    .ellipsisAjax {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}

//ajax loading
.preloaderSliderAjax, .sliderAjax {
    position: absolute;
    left: 15px;
    right: 0;
    bottom: 0;
    top: 0;
    margin-top: 45px;
    background-color: $white;
    padding: 15px 30px;
    width: 85%;
    min-height: 350px;
    border-radius: 30px;
    opacity: 0.5;

    h3 {
        position: absolute;
        top: 50%;
        color: red;
        transform: translatey(-50%);
    }
}

.preloaderSliderAjaxSecond, .requestLoadingEvent {
    position: sticky;
    left: 0px;
    right: 0;
    bottom: 0;
    top: 0;
    margin-top: 45px;
    background-color: $white;
    padding: 15px 30px;
    width: 100%;
    height: 100vh;
    opacity: 0.5;
}
.extraSliderAjax{opacity:0.9 !important;}

//ajax loading into company div
.preloaderCompanyAjax {
    position: absolute;
    padding: 0;
    width: 100%;
    min-height: 210px;
    border-radius: 0;
    background-color: rgba(255, 255, 255, 0.3);
}
#validZip, .validZip{
    border:solid 1px green !important;
}
.requestLoadingEvent{
    z-index:99999;
}

.invalid{border:solid 1px red !important;}
.success{border:solid 1px green !important;}
#insurance_start_date-error, #license_category-error, #termsvalidate-error, #sex-error, type-error, #license_plate-error, #cubic_capacity-error, #current_price-error, #dob-error, #vat-error, #license_date-error, #mobile-error, #email-error, #-error {
    display: none !important;
}
#first_name-error, #last_name-error, #address-error, #address_2-error, #phone-error {
    display: none !important;
}

/// THIS IS FOR STEP 3
.progressStepThree {
    background: linear-gradient(to left, #CCA -1%, #ccc 0%, #0e3c66 0%) !important;
}

.thirdStepChosenCompany {
    margin-top: 50px;
    margin-bottom: 50px;

    h4 {
        margin-bottom: 0;

        @media screen and (max-width:580px) {
            font-size: 12px;
        }
    }

    @media screen and (max-width:767px) {
        margin-bottom: 20px;
    }
}

.smallerDevider {
    margin: 5px 0;
}

.analysisIns {
    color: $darkBlue;
    padding: 15px 0;
    padding-left: 15px;

    @media screen and (max-width:991px) {
        display: none;
    }
}

.basicStyle {
    background-color: $white;
    border-bottom: solid 3px $galazioLight;

    .borderR {
        border-right: dotted 1px #ccc;
    }

    .borderL {
        border-left: dotted 1px #ccc;
    }
}

.listStyle {
    background: linear-gradient(90deg, $galazioDark, $galazioLight);
    width: 100%;

    ul {
        padding: 10px 0 10px 30px;

        li {
            color: $white;
        }
    }
}

#coverSec {
    width: 100%;
}

.btnCov {
    margin-bottom: 50px;

    @media screen and (max-width:767px) {
        margin-bottom: 20px;
    }
}

.backgroungChange {
    background: linear-gradient(90deg, $galazioDark, #1a8ec1);
    color: $white !important;
    transition: background-color 400ms cubic-bezier(0.2,0.5,0.1,0);
}

.backgroungChange992 {
    background: linear-gradient(90deg, $galazioDark, $galazioLight);
    color: $white !important;
    transition: background-color 400ms cubic-bezier(0.2,0.5,0.1,0);
}

.checkout .backgroungChange {
    background: $white;
    color: $galazioDark !important;
    transition: background-color 400ms cubic-bezier(0.2,0.5,0.1,0);
}

#btnInsCov {
    &:hover {
        cursor: pointer;
    }
}

.btnThirdTerms {
    padding-top: 30px;
    padding-bottom: 30px;
}

.basicForminsurancePart2 {
    margin-bottom: 30px;
}

#paymethod-error, #nameThirdStep-error, #lastNameThirdStep-error, #addressThirdStep-error, #cityThirdStep-error, #emailThirdStep-error, #mobileThirdStep-error {
    display: none !important;
}

.analysisInsThirdPart {
    color: $darkBlue;
    padding: 15px 0;
    padding-left: 15px;
}
/// THIS IS FOR STEP 3
.termsofuse {
    margin-top:50px;
    p{font-weight:300;}
}
.loaderResults {
    min-height: 100vh;
    .lds-ellipsis {
        div {
            display: block !important;
            z-index: 99999;
        }
    }
}
///thank you page
@mixin vertical-align($position: relative) {
    position: $position;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

#wrapper {
    margin: 0 auto;
    padding-top: 5%;
    padding-bottom: 15%;
    background-color: #f7f7f7;

    i {
        font-size: 5rem;
        padding: 40px 0 10px 0;
        color: #0e3c66;
    }

    p {
        padding-top: 20px;
    }

    .depositInfo{
        margin-top:30px;
        ul{
            display:inline-block;
            text-align:left;
            li{
                list-style:none;
                padding:10px 5px 10px 0px;
                border-bottom:solid 1px #ccc;
            }
        }
    }

    @media screen and (max-width:992px) {
        h1 {
            font-size: 25px;
        }
    }
}

h1 {
    color: #0e3c66;
    text-shadow: -1px -2px 3px rgba(17, 17, 17, 0.3);
    text-align: center;
    font-family: "Monsterrat", sans-serif;
    font-weight: 900;
    text-transform: uppercase;
    font-size: 60px;
    margin-bottom: -5px;
}

h1 underline {
    border-bottom: 5px solid #0e3c66;
}

h3 {
    margin-left: 16px;
    font-family: "Lato", sans-serif;
    font-weight: 600;
    color: #0e3c66;
}

.ui-autocomplete-loading {
    background-image: url('../img/frontend/field-loader.gif'); // image reggenerate
    background-repeat: no-repeat;
    background-position: 98% 45%;
    background-size: 16px 16px;
}

//LOADING BANNER==========================
#loadingbanner {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #070707a8;
    z-index: 9;

    .wrapperLoadingBanner {
        background-color: $white;
        position: absolute;
        left: 50%;
        right: 0;
        bottom: 0;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 700px;
        height: 350px;
        background-position: left;
        background-size: contain;
        background-repeat: no-repeat;
        border-radius: 5px;

        .bgLoadingBanner {
            padding-right: 20px;

            .message-block {
                display: block;
                width: 70%;
                max-width: 470px;
                height: 250px;
                padding: 40px 35px 0 0;
                float: right;
            }

            a {
                background-color: $orange;
                color: $white;
                border-radius: 3px;
                border: none;
                padding: 10px 15px;
                font-size: 13px;
                font-weight: 600;
                position: absolute;
                bottom: 30px;
                right: 50px;
            }

            h3 {
                border-bottom: solid 2px grey;
                width: 100%;
                font-weight: 800;
                margin-bottom: 40px;
                color: $galazioLight;
            }

            p {
                font-size: 14px;
            }
        }

        @media screen and (max-width:1024px) {
            .bgLoadingBanner {
                button {
                    bottom: 10px;
                }
            }
        }

        @media screen and (max-width:768px) {
            .bgLoadingBanner {
                h3 {
                    font-size: 17px;
                    top: 10px;
                }

                button {
                    bottom: 10px;
                }

            }

            background-size: cover;
        }

        @media screen and (max-width:580px) {
            width: 90%;

            .bgLoadingBanner {
                p {
                    font-size: 11px;
                }
            }
        }
    }
}

//LOADING BANNER==========================



.ifBankTransfer {
    display:none;
    .donate-now label {
        padding: 15px 50px 5px 50px;
    }
}
.ifCreditCard{
    display:none;
}

@media screen and (max-width:767px){
    .donate-now label {
            padding: 15px 15px 5px 15px;
    }
    .ifBankTransfer {
        display: none;

        .donate-now label {
            padding: 15px 15px 5px 15px;
        }
    }
}

.resetlistStyle {
    .listStyle{
        width:unset;
    }
}

.entipoTrapezas {
    a {
        color: #0e3c66;
        font-weight: 700;

        &:hover {
            font-weight: 700;
            color:$galazioDark;
        }
    }
}
.priceFixAl{
    margin-top:20px;
}
//checkout

//COOKIES
.cookies {
    background-color: $darkBlue;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9;
    padding: 20px 15px;
    font-size: 1.2rem;

    p {
        margin-bottom: 0;
        color: $white;
    }

    .cookieOk {
        background-color: $orange;
        color: $white;
        padding: 5px 35px;
        border: none;
        border-radius: 20px;
        width: 270px;

        &:hover {
            background-color: $galazioLight;
        }

        &:focus {
            outline: none;
        }

        @media screen and (max-width:1024px) {
            width: 100%;
            padding: 5px 5px;
        }

        @media screen and (max-width:767px) {
            width: 100%;
            max-width: 185px;
            padding: 5px 30px;
        }
    }

    .cookieWhatsIt {
        background-color: transparent;
        border: 1px solid #fff;
        color: $white;
        width: 90%;
        padding: 5px 60px;
        border-radius: 20px;

        &:hover {
            color: #c3c3c3;
        }

        @media screen and (max-width:1024px) {
            width: 100%;
            padding: 5px 0;
        }

        @media screen and (max-width:767px) {
            width: 100%;
            padding: 5px 30px;
        }
    }

    .rowInWhatsIt {
        margin-top: 6px;

        @media screen and (max-width:767px) {
            text-align: center !important;
            font-size: 15px;
            margin-bottom: 15px;
        }
    }

    .respCookie {
        @media screen and (max-width:767px) {
            margin-top: 10px;
        }
    }

    @media screen and (max-width:767px) {
        text-align: center;
        font-size: 12px;
    }
}

.chasm-documents-list {
    text-align: left;
    list-style-type: none;
    margin-top: 60px;
    margin-left: 20px;
    padding: 0;
}

.chasm-custom-flex-align-items-center {
    align-items: center;
}

.gr-chasm-flex {
    display: -webkit-flex;
    display: flex;
}

.chasm-documents-list-item {
    border-bottom: 1px solid #ccc;
    margin-bottom: 1em;
    padding-bottom: 1em;
    padding-left: 1em;
    display: none;
}

.chasm-documents-document-title { padding-left: 15px; }

.chasm-documents-form {
    margin-left: auto;
    margin-right: auto;
    max-width: 360px;
}
.chasm-common-form .ti-angle-down {
    background-color: #1474db;
    color: #fff;
    font-size: 30px;
    padding: 10px;
    right: 0;
    top: 0;
}
.ti-angle-down:before {
    content: "\e64b";
}
.chasm-documents-form .chasm-common-form-field {
    width: 100% !important;
    height: 50px;
    font-size: 16px;
    -moz-transition: all .2s;
    -o-transition: all .2s;
    -webkit-transition: all .2s;
    transition: all .2s;
    background-color: #fff;
    border: 3px solid #1474db;
    color: #1474db;
}
.chasm-common-form-field:hover {
    border: 3px solid #1474db;
}
.chasm-documents-company-select option {
    background-color: #1474db;
    color: #fff;
}

ul.gr-chasm-pirbred-contact li {
  list-style: none;
}

.content-text {
    font-weight: normal;
}

.content-text ul li {
    margin-left: 18px !important;
}

@media screen and (max-width:767px) {
    .hidden-phone { display: none !important; }
}

@media screen and (max-width: 979px) and (min-width: 768px) {
    .hidden-tablet { display: none !important; }
}
