//car Insurance start

//6iconsnew
.cont-6-1{
    background:#f2eeee;
}
.col-lg-4.comp-div {
    text-align: center;
    background: white;
    border: 50px solid #f2eeee;
    min-height: 350px;
    border-radius:60px;
}

.six{
    background:#f2eeee;
  }

.col-lg-12.comparison-image-div{
    bottom: 2rem;
    img{
        max-width:30%;
    }
}
.comparison-text-title {

    font-size: 0.9rem;
    font-weight: 500;
}
.comp-t-span {

    font-size: 1rem;
}
//banner
.body{
    font-family: 'Open Sans', sans-serif;
}
.banner-button{
    button {
        color: #fff;
        background-color: #ef5630;
        border: none;
        border-radius: 10px;
        padding: 10px 25px;
        font-weight: 500;
        font-family: 'OpenSans', sans-serif;
    }
}
.container-fluid.banner{
    background-image:url("/storage/uploads/banner-car-insurance.jpg");
    background-repeat:no-repeat;
    min-height:500px;
    background-size: contain;
    background-position:left;
    max-width: 100%;
    a{
        color:white;

    }
    .text1{
        top:150px;
        line-height: 30px;
        text-align:left;

        p.text1{
            color:white;
            font-size:2.5rem;
            padding-left:20px;
            font-weight: 400;
        }
        p.text2{
            color:white;
            font-size:2.4rem;
            padding-left:20px;
        }
    }
    .text3 {
        top: 11rem;
        line-height: 40px;
        text-align: center;
        background-color: $orange;
        border-radius:10px;
        left:2.2rem;
        p{
            color:white;
            font-size:1rem;
            text-align: center;
            padding-top:20px;
        }
    }
    .text4 {
        top: 13rem;
        color:white;
        line-height: 1rem;
        text-align: left;
        left:1.5rem;
        p.text4-1{
            font-weight: 500;
        }
        p.text4-2{
            font-style:italic;
        }
    }
}

//6icons
.container-fluid.cont-6-1{
    background-color: #f2eeee;
    padding-top:3%;
    padding-bottom:3%;
    text-align:center;
    .text2{
        h3{
            color: #464545;
            margin-top: -1rem;
        }
    }
}
.col-lg-12.text1{
    font-weight:100;
}
.six-icons-heading-div{
    text-align:center;
}
.icons-6{
    padding-bottom:30px;
}
.container-fluid-6 {
    background-color: #f2eeee;
    .container.icons-6-text{
        padding-bottom:100px;
        padding-top:100px;
        text-align:center;
        .text1{
            font-weight: 400;
            margin-bottom:-10px
        }
        .text2{
            h3{
                color:#464545;
                font-weight:bolder;
            }
        }
    }
    .container.icons-6 {
        background-color: #f2eeee;
        padding-bottom: 45px;

        .icons-row {
            padding-bottom:100px;
            .comparison-div {
                background-color: white;
                max-width: 270px;
                min-height: 250px;
                border-radius: 10px;
                margin-bottom: 3rem;

                img {
                    max-width: 35%;
                }
                .text-div{
                    font-size:0.9rem;
                    padding-top:1.5rem;
                }

                .comparison-text-title {
                    text-align: center;
                    font-weight: 400;

                }

                .comparison-text-main {
                    text-align: center;
                    font-weight: 300;
                    font-size:0.8rem;
                }

                .comparison-image-div {
                    max-width: 90%;
                    text-align: center;
                    left: 1rem;
                    bottom: 1.5rem;
                }
            }

        }
    }
}
//Small Letters
.container.small-letters{
    .row.title-small-letters{
        text-align:center;
        .sl-heading{
            padding-top:30px;
            padding-bottom:30px;
        }
        h3.title-small-letters{
            color:#464545;
        }
    }
    .why-div{
        top:3rem;
    }
    .home-ins-img{
        img{
            box-shadow: -9px 5px 47px #383737;
        }
    }
    .content1-div {
        padding-bottom: 30px;
        color:#464545;
        p{
            font-size: 0.9rem;
        }


    }
    .content2-div {
        padding-bottom: 30px;
        color:#464545;
        p{
            font-size: 0.9rem;
        }


    }
    .content3-div {
        padding-bottom: 30px;
        color:#464545;
        p{
            font-size: 0.9rem;
        }


    }
    .content4-div {
        padding-bottom: 110px;
        color:#464545;
        p{
            font-size: 0.9rem;
        }


    }
    .content1-title{
        color:$darkGrey;
        font-size:1rem;
        font-weight:600;
        text-align: center;
    }
    .content1-text{
        font-weight:400;
        font-size:0.8rem;
        text-align: center;
    }
    .content2-title{
        color:$darkGrey;
        font-size:1rem;
        font-weight:600;
        text-align: center;
    }
    .content2-text{
        font-weight:400;
        font-size:0.8rem;
        text-align: center;
    }
    .content3-title{
        color:$darkGrey;
        font-size:1rem;
        font-weight:600;
        text-align: center;
    }
    .content3-text{
        font-weight:400;
        font-size:0.8rem;
        text-align: center;
    }
    .content4-title{
        color:$darkGrey;
        font-size:1rem;
        font-weight:600;
        text-align: center;
    }
    .content4-text{
        font-weight:400;
        font-size:0.8rem;
        text-align: center;
    }
}
//car insurance

.aa{
    padding-bottom: 1rem;
    h3{
        color:#464545;
    }
}
.container-fluid.car-insurance{
    padding-top: 4rem;
    background-image: url("uploads/Page2-Back1.jpg") ;
    .col-lg-12{
        text-align: center;
        h3{
            color:#464545;
        }
    }
    .title2{
        font-weight: 300;
    }
    #list18{
        h3{
            padding-top:1rem;
        }
    }

    .container{
        padding-top:2rem;
        padding-bottom:4rem;
        .cardone{
            border-block-start: 0;
            border-left: 0;
            border-right: 0;
            background:none;
            .fas.fa-plus{
                color:$orange;
                padding-right:10px;
            }

        }
    }
}



//estimate insurance cost
.banner-5{
    background-image:url('/storage/uploads/blackcarbackground.jpg');
    background-repeat:no-repeat;
    min-height:500px;
    background-position: center;
    background-size: cover;
    a{
        color:white;
    }
    .text5-1{
        padding-top:10vh;
        color:white;
        .h2{
            font-weight:600;
        }
    }
    .text6-1{
        text-align: center;
        color:white;
        padding-top:5vh;
        p{
            font-size:1rem;
            font-weight:400;
        };

    }
    .text4 {
        top: 3rem;
        line-height: 30px;
        text-align: center;
        background-color: $orange;
        border-radius: 10px;
        padding-top: 15px;
        color:white;
    }
}
@media screen and (min-width:1126px ){
    .last-button-c{
        top:30px;
    }
}
//last car insurance part
.last-button-c{
    text-align: center;
    .button-last-c{
        color: #fff;
        background-color: #ef5630;
        border: none;
        border-radius: 10px;
        padding: 1rem 1rem;
        font-weight: 500;
        p{
            margin-bottom:1px;
            margin-top:1px;
            a{
                color: white;
                font-weight: bolder;
            }
        }
    }
}

//car insurance end

//home insurance start
//banner

.container-fluid.banner2{
    background-image:url("/storage/uploads/2-Banner.jpg");
    background-repeat:no-repeat;
    min-height:480px;
    background-position: top;
    margin-left:-10px;
    a{
        color:white;
    }
    .banner2-rcol {
        padding-left: 10%;
        margin-top: 40px;
    }

    .banner2-fcol{
        top:150px;
        padding-left:35%;
        .banner-button{
            padding-top:15px;
            padding-bottom:25px;
        }
        .button-first{
            p{
                margin:0.8rem;
                font-family: 'OpenSans', sans-serif;
                color: white;
                font-weight: bold;
            }
        }
        .text-b-1 {
            margin-bottom: -13px;
            h4 {
                font-size: 45px;
                font-family: 'OpenSans', sans-serif;
                color: white;
                font-weight: lighter;
            }
        }
        .text-b-2{
            h4 {
                font-size: 45px;
                font-family: 'OpenSans', sans-serif;
                color: white;
                font-weight: lighter;
            }
        }
        .text-b-3{
            padding-top:8px;
            span{
                font-size: 18px;
                font-family: 'OpenSans', sans-serif;
                color: white;
                font-weight: 500;
            }
        }
        .text-b-4{
            padding-top:8px;
            span{
                font-size: 18px;
                font-family: 'OpenSans', sans-serif;
                color: white;
                font-weight: 600;
                font-style: italic;
            }
        }
    }
}


//6icons
.content{
    padding-right:1px;
    padding-left:1px;
}

.container.icons-62-text20{
    padding-bottom:100px;
    padding-top:100px;
    text-align:center;
    .text21{
        font-weight: 400;
        margin-bottom:-15px;
        color:#464545;
    }
    .text22{
        h3{
            color:#464545;
            font-weight:bolder;
        }
    }
}
.six-icons2-heading-div{
    text-align:center;

}
.container-fluid-62 {
  background-color: #f2eeee;
    .container{
        .content {
            padding-bottom: 3.5rem;
        }

        img{
            width:20%;
            top:1rem;
            padding-top:2rem;
        }
        .icons2-img-div{
            background-color:#1584bc;
            text-align: center;
            border-top-right-radius: 10px;
            border-top-left-radius: 10px;
            min-height:6.5rem;
            box-shadow: -9px 5px 47px #383737;
        }
        .icons2-text-div{
            background-color:white;
            font-weight: 500;
            padding-top: 3rem;
            text-align: center;
            font-size: 0.9rem;
            min-height: 200px;
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 10px;
            box-shadow: -9px 5px 47px #383737;
        }
    }
}
//home insurance two
.container.small-letters{
    padding-bottom: 30px;
    padding-top: 50px;
    img{
        width:80%;
    }
    p{
        font-size:0.8rem;
        font-weight:400;
    }
}
.home-ins-txt1{
    padding-top:60px;
}
.why-div{
    padding-top:50px;
}
//accordion
.card-body{
    font-weight:400;
}


//estimate insurance cost
.banner-25{
    background-image:url('/storage/uploads/page2-back2.jpg');
    background-repeat:no-repeat;
    min-height:642px;
    background-size: cover;
    .text25-1{
        color:#313131;
        text-align: center;
        top:9rem;
        .h4{
            font-size:1.8rem !important;
        }
    .text1{
        font-weight:bold;
    }
    }
    .text26-1{
        text-align: center;
        top:12rem;
        p{
            font-size:1rem;
            color:#575757;
            font-weight:100;
        };

    }
    .text24-foo {
        line-height: 30px;
        text-align: center;
        background-color: $orange;
        border-radius: 10px;
        padding-top: 15px;
        color:white;
        top:18rem;
        left:2.5rem;
        a{
            color:white;
            font-family:'OpenSans',sans-serif;
            font-size:0.9rem;
            font-weight:600;
        }
    }
}
//last

.text24-fo{
    top: 300px;
    text-align: center;
    .button-last{
        color: #fff;
        background-color: #ef5630;
        border: none;
        border-radius: 10px;
        padding: 1.5rem 1.5rem;
        font-weight: 500;
        p{
            color: white;

            text-align: center;

            font-weight: 400;

            margin-bottom: 1px;

            margin-top: 1px;
            a{
                color: white;
                font-weight: bolder;
            }
        }
    }
}

@media screen and (max-width: 373px){
    .banner-25{
        padding-top:0 !important;
    }
    .col-lg-12.text24-fo{
        top: 0 !important;
    }
}
@media screen and (max-width: 481px){
    .banner-25{
        padding-top:100px !important;
    }
    .text26-1{
        top:2rem !important;
    }
    .text24-fo{
        top:3rem !important;
    }

}




@media screen and (max-width: 1199px){
    .col-lg-12.text24-fo{
        top:15rem;
    }
    .row.main2-2-small-letters{
        padding-left: 100px;
    }
    .row.main2-small-letters{
        padding-left: 100px;
    }
    .why-div{
        padding-top:100px;
    }
    .home-ins-txt1{
        padding-top:0px;
    }
}
@media screen and (max-width: 991px){
    .why-div{
        padding-top:50px;
    }
    .home-ins-img{
        padding-top:40px;
    }
}
@media screen and (max-width: 767px) and (min-width: 742px) {
    .home-ins-img2 {
        padding-top: 330px;
    }
}
@media screen and (max-width: 767px){
    .why-div{
        top:0rem !important;
    }
    .home-ins-img{
        padding-bottom: 30px;
    }
}
@media screen and (max-width: 1377px){
    .container-fluid.banner-25{
        text-align: center;
        .text24{
        }
    }
}
@media screen and (max-width: 1054px){
    .container-fluid.banner2{
        background-image:none;
    }
    .container-fluid.banner2f{
        background-image:none;
    }
    .text21{
        color:black !important;
        text-align: center !important;
        p.text21{
            text-align: center;
        }
    }
    .text22{
        color:black !important;
    }
    .text23{
        left:6rem !important;
    }
    .text24-1{
        color:black !important;
    }
    .text24-2{
        color:black !important;
    }
    .text24{
        p{
            text-align: center;
        }
    }
    .col-lg-2.offset-5.text24{
    }
    .main2-small-letters{
        min-height:17rem !important;
    }
}

@media screen and (max-width: 742px){
    .text24-foo{
        top:13rem !important;
    }
}
@media screen and (min-width: 742px){
    .main2-small-letters{
        height:17.8rem;
    }
}
@media screen and (max-width: 481px){
    .text24-foo{
        p{
            font-size:0.8rem !important;
        }
    }
    .text25-1{
        top:0 !important;
    }
    .text26-1{
        top:0 !important;
    }
}
@media screen and (max-width: 440px){
    .text24-foo{
        top:0 !important;
        left:0 !important;
        p{
            font-size:0.8rem;
        }
    }
    .container-fluid.banner2{
        .text23{
            left:3rem !important;
        }
        .col-lg-12.text21{
            padding-bottom:30px;
        }
    }

}


//insurance foreigners
//banner
.container-fluid.banner2f{
    background-image:url("/storage/uploads/4-Banner.jpg");
    background-repeat:no-repeat;
    min-height:480px;
    background-position: top;
    margin-left:-10px;
    a{
        color:white;
    }
    .banner2-fcol{
        top:150px;
        padding-left:30%;
        .banner-button{
            padding-top:15px;
            padding-bottom:25px;
        }
        .button-first{
            p{
                margin:0.8rem;
                font-family: 'OpenSans', sans-serif;
                color: white;
                font-weight: bold;
            }
        }
        .text-b-1 {
            margin-bottom: -13px;
            h4 {
                font-size: 45px;
                font-family: 'OpenSans', sans-serif;
                color: white;
                font-weight: lighter;
            }
        }
        .text-b-2{
            h4 {
                font-size: 45px;
                font-family: 'OpenSans', sans-serif;
                color: white;
                font-weight: lighter;
            }
        }
        .text-b-3{
            padding-top:8px;
            span{
                font-size: 18px;
                font-family: 'OpenSans', sans-serif;
                color: white;
                font-weight: 500;
            }
        }
        .text-b-4{
            padding-top:8px;
            span{
                font-size: 18px;
                font-family: 'OpenSans', sans-serif;
                color: white;
                font-weight: 600;
                font-style: italic;
            }
        }
    }
}
.container-fluid.banner2c{
    background-image:url("/storage/uploads/banner-car-insurance.jpg");
    background-repeat:no-repeat;
    min-height:480px;
    background-position: top;
    margin-left:-10px;
    a{
        color:white;
    }
    .banner2-fcol{
        top:150px;
        padding-left:30%;
        .banner-button{
            padding-top:15px;
            padding-bottom:25px;
        }
        .button-first{
            p{
                margin:0.8rem;
                font-family: 'OpenSans', sans-serif;
                color: white;
                font-weight: bold;
            }
        }
        .text-b-1 {
            margin-bottom: -13px;
            h4 {
                font-size: 45px;
                font-family: 'OpenSans', sans-serif;
                color: white;
                font-weight: lighter;
            }
        }
        .text-b-2{
            h4 {
                font-size: 45px;
                font-family: 'OpenSans', sans-serif;
                color: white;
                font-weight: lighter;
            }
        }
        .text-b-3{
            padding-top:8px;
            span{
                font-size: 18px;
                font-family: 'OpenSans', sans-serif;
                color: white;
                font-weight: 500;
            }
        }
        .text-b-4{
            padding-top:8px;
            span{
                font-size: 18px;
                font-family: 'OpenSans', sans-serif;
                color: white;
                font-weight: 600;
                font-style: italic;
            }
        }
    }
}
//easy insurance

.simple-insurance{
    padding-bottom: 5rem;
    padding-top: 5rem;
    text-align: center;
    h3{
        color:#464545;
        margin-top:-1rem;
    }
    p{
        font-weight: 400;
    }
}
.number{
    margin-bottom: 0px;
}
.easy-first{
    background-image: url('uploads/page4-bt1.jpg');
    background-repeat:no-repeat;
    min-height: 24rem;
    text-align: center;
    background-size: cover;
    .easy-first-row{
        padding-top:5rem;
        .number{
          font-size:4rem;
          font-weight:100;
          color:#888;
        }
        .title{
            color:#686868;
            font-size:1.3rem;
        }
        .text{
            font-weight:100;
            color:#5b5b5b;
        }
    }
}
.easy-second{
    background-color: white;
    background-repeat:no-repeat;
    min-height: 24rem;
    text-align: center;
    background-size: cover;
    .easy-first-row{
        padding-top:5rem;
        .number{
            font-size:4rem;
            font-weight:100;
            color:#d9d9d9;
        }
        .title{
            font-weight: bold;
            color:#4d4d4d;
            font-size:1.4rem;
        }
        .text{
            font-weight:100;
        }
    }
}
.easy-third{
    background-color: #f2f0f1;
    background-repeat:no-repeat;
    min-height: 24rem;
    text-align: center;
    background-size: cover;
    .easy-first-row{
        padding-top:5rem;
        .number{
            font-size:4rem;
            font-weight:100;
            color:#d9d9d9;
        }
        .title{
            font-weight:bold;
            font-size:1.4rem;
        }
        .text{
            font-weight:100;
        }
    }
}
.easy-fourth{
    background-image: url('uploads/page4-bt2.jpg');
    background-repeat:no-repeat;
    min-height: 24rem;
    text-align: center;
    background-size: cover;
    .easy-first-row{
        padding-top:5rem;
        .number{
            font-size:4rem;
            font-weight:100;
            color:white;
        }
        .title{
            font-weight: bold;
            color:white;
            font-size:1.4rem;
        }
        .text{
            font-weight:400;
            color:white;
        }
    }
}
.easy-fifth{
    background-image: url('uploads/page4-bt3.jpg');
    background-repeat:no-repeat;
    min-height: 24rem;
    text-align: center;
    background-size: cover;
    .easy-first-row{
        padding-top:5rem;
        .number{
            font-size:4rem;
            font-weight:100;
            color:white;
        }
        .title{
            font-weight: bold;
            color:white;
            font-size:1.4rem;
        }
        .text{
            font-weight:400;
            color:white;
        }
    }
}
.easy-sixth{
    background-color:white;
    background-repeat:no-repeat;
    min-height: 24rem;
    text-align: center;
    background-size: cover;
    .easy-first-row{
        padding-top:5rem;
        .number{
            font-size:4rem;
            font-weight:100;
            color:#d9d9d9;
        }
        .title{
            font-weight: bold;
            font-size:1.3rem;
        }
        .text{
            font-weight:100;
        }
    }
}

//blocks3
.pre-blocks{
    background-color:#f4f4f4;
    padding-bottom: 5rem;
    padding-top: 5rem;
    .text1{
        font-weight: 300;
        strong{
            font-weight: bold;
        }
    }
    .text333{
        font-weight:300;
        strong{
            font-weight: bold;
        }
    }
    .container.blocks-3-3{
        .strong{
            font-weight: bold;
        }
    }
}

.last1{
    background-color:white;
    padding-top: 5rem;
    padding-bottom: 5rem;
    .last2{
        background: linear-gradient(90deg,#1584bc,#2eb8d5);
        color: white;
        padding-top: 70px;
        padding-bottom: 30px;
        border-radius: 30px;
        min-height: 27rem;
        box-shadow: -9px 5px 47px #383737;
    }
    .last-title{
        color:white;
        text-align:center;
        font-weight:bold;
        font-size:1.7rem;
    }
    p{
        color:white;
        text-align:center;
        font-weight:400;
        margin-bottom: 1px;
        margin-top: 1px;
    }
    .last-2-tex-div{
        padding-top:2rem;
    }
    .last-button{
        text-align: center;
        padding-top:2rem;

    }
    .button-last {
        color: #fff;
        background-color: #ef5630;
        border: none;
        border-radius: 10px;
        padding: 1.5rem 1.5rem;
        font-weight: 500;
        a{
            color:white;
            font-weight:bolder;
        }
    }
}
@media screen and (min-width: 992px) {
    .cont-first {
        margin-left: 4%;
    }
}


@media screen and (max-width: 1377px){
    .container-fluid.banner4{
        text-align: center;
        .text24{
            top:2px !important;
        }
    }
}
@media screen and (max-width: 1054px){
    .container-fluid.banner4{
        background-image:none;
    }
    .text41{
        color:black !important;
    }
    .text42{
        color:black !important;
    }
    .text43{
        left:6rem !important;
    }
    .text44-1{
        color:black !important;
    }
    .text44-2{
        color:black !important;
    }
    .text44{
    }
    .col-lg-2.offset-5.text44{
    }
}
@media screen and (max-width: 440px){
    .container-fluid.banner-4{
        .text42{
            padding-left:0 !important;
        }
        .text43{
            left:4rem !important;
        }
    }

}

@media screen and (max-width:767px){
    .last-button{
        top:-0.5rem !important;
    }
    .last2{
        border-radius:50px;
    }
}
@media screen and (max-width:1263px){
    .last-button{
        padding-bottom: 20px;
    }
}

@media screen and (max-width: 1377px){
    .container-fluid.banner-45{
        text-align: center;
        .text44{
            top:2px !important;
        }
    }
}
@media screen and (max-width: 1054px){
    .container-fluid.banner4{
        background-image:none;
    }
    .text41{
        color:black !important;
    }
    .text42{
        color:black !important;
    }
    .text43{
        left:6rem !important;
    }
    .text44-1{
        color:black !important;
    }
    .text44-2{
        color:black !important;
    }
    .text44{
    }
    .col-lg-2.offset-5.text44{
    }
}
@media screen and (max-width: 440px){
    .container-fluid.banner4{
        .text42{
            padding-left:0 !important;
        }
        .text43{
            left:4rem !important;
        }
    }

}
@media screen and (max-width: 440px){
    .container-fluid.banner4{
        .text42{
            padding-left:0 !important;
        }
        .text43{
            left:4rem !important;
        }
        .text41{
            text-align:center;
        }
        .col-lg-12.text41{
            padding-bottom:30px;
        }
    }

}
@media screen and (min-width:2160px) {
    .banner2-fcol {
        top: 150px;
        padding-left: 20% !important;
    }
}
@media screen and (min-width:2160px) {
    .banner2-fcol {
        top: 150px;
        padding-left: 14% !important;
    }
}
@media screen and (max-width:1052px){
    .banner2{
        background-image: none;
    }
    .banner2f{
        background-image:none!important;
    }
    .banner2c{
        background-image:none!important;
    }
    .banner2-fcol{
        padding-left:5%!important;
        text-align: center!important;
    }
    .col-lg-12.text-b-1{
        h4{
            color:black !important;
        }
    }
    .col-lg-12.text-b-2{
        h4{
            color:black !important;
        }
    }
    .col-lg-12.text-b-3{
        span{
            color:black !important;
        }
    }
    .col-lg-12.text-b-4{
        span{
            color:black !important;
        }
    }
}
